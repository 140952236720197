import * as React from 'react';
import classnames from 'classnames';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { ISocialAuthLogin, ISocialIcons } from '../MemberLoginDialog.types';
import Preloader from '../../Preloader/viewer/Preloader';
import { serializeIframeSource } from './SmallSocialIcons';
import { MemberLoginDialogTranslationKeys as keys } from './constants';

export const SocialAuth: React.FC<ISocialAuthLogin> = ({
  id,
  emailSectionDivider,
  switchToAuthWithEmail,
  enabledSocialVendors,
  iframeConfig,
  goToEmailAuthScreen,
  isEmailLoginEnabled,
  style,
  mode,
  translate,
}) => {
  return (
    <>
      <div className={style.socialLoginWrapper}>
        <SocialIcons
          id={id}
          enabledSocialVendors={enabledSocialVendors}
          iframeConfig={iframeConfig}
          style={style}
          mode={mode}
          translate={translate}
        />
      </div>

      {isEmailLoginEnabled && (
        <>
          <div
            className={classnames(
              style.emailSectionDivider,
              style.sectionDivider,
            )}
          >
            <span className={style.emailSectionDividerText}>
              {emailSectionDivider}
            </span>
          </div>
          <div data-testid="switchToEmailLink" className={style.expandButton}>
            <BasicButton
              label={switchToAuthWithEmail}
              data-testid="switchToEmailLink"
              id={`switchToEmailLink_${id}`}
              isDisabled={false}
              hasPlatformClickHandler={true}
              link={undefined}
              onClick={() => goToEmailAuthScreen(false)}
            />
          </div>
        </>
      )}
    </>
  );
};

export const SocialIcons = React.forwardRef<
  HTMLIFrameElement,
  Omit<ISocialIcons, 'socialSectionDivider'>
>(
  (
    {
      enabledSocialVendors,
      iframeConfig,
      style,
      mode,
      translate,
      isHorizontal,
      ready, // Will be set to true if we've recieved a PAGE_READY postMessage from the iframe
      error, // Will be set to a non-empty string if a postMessage handler threw an error
    },
    ref,
  ) => {
    const src = serializeIframeSource(
      { ...iframeConfig, enabledSocialVendors, mode },
      !!isHorizontal,
    );
    const height = Array.from(enabledSocialVendors).length * 75 + 'px';

    const [isSocialIframeLoaded, setSocialIframeLoaded] = React.useState(
      !!ready,
    );

    const socialLoginIframeClass = classnames(style.socialLoginIframe, {
      [style.ready]: isSocialIframeLoaded || ready,
    });
    const title = translate(keys.social.iframeTitle, 'Social login');

    return (
      <div className={style.socialLoginWrapper}>
        <iframe
          data-testid="socialAuthIframe"
          src={src}
          className={socialLoginIframeClass}
          title={title}
          frameBorder="0"
          scrolling="no"
          style={{ height }}
          onLoad={() => setSocialIframeLoaded(true)}
          ref={ref}
        />
        <Preloader enabled={!(isSocialIframeLoaded || ready)} dark={true} />
        <p className={style.socialLoginErrorMsg}>{error}</p>
      </div>
    );
  },
);
